import React, { useState } from "react";
import { Row, Col, Typography, Card, Button, Input, Form, message } from "antd";
import { Content } from "antd/es/layout/layout";
import "antd/dist/antd.css";
import Shankh from "../assets/images/contactus.svg";
import "../assets/styles/forgotPassword.css"; // Import the CSS file for the forgot password page
import { HOST_URL } from "../helper/constant";
import { useHistory } from "react-router-dom";

const { Title } = Typography;

const ForgotPassword = () => {
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  const onFinish = async (values) => {
    setLoading(true);
    try {
      const response = await fetch(
        `${HOST_URL}password-reset-link/request-reset/`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ email: values.email }),
        }
      );

      if (response.ok) {
        message.success("Password reset link sent to your email.");
        setTimeout(() => {
          history.push("/homepage");
        }, 2000);
      } else {
        const errorData = await response.json();
        message.error(errorData.message || "Failed to send reset link.");
      }
    } catch (error) {
      message.error("An error occurred while sending the reset link.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Content>
      <Row className="container">
        <Col xs={24} sm={12} md={8} className="character">
          <img src={Shankh} alt="Forgot Password" />
        </Col>
        <Col xs={24} sm={12} md={16} className="info-forgot-password">
          <div className="message">
            <Title level={2} style={{ color: "white" }}>
              Forgot Your Password?
            </Title>
            <p>
              Enter your email address below to receive a password reset link.
            </p>
            <Form onFinish={onFinish} layout="vertical">
              <Form.Item
                name="email"
                label="Email Address"
                rules={[
                  {
                    required: true,
                    message: "Please enter your email address",
                  },
                  {
                    type: "email",
                    message: "Please enter a valid email address",
                  },
                ]}
              >
                <Input
                  placeholder="Enter your email"
                  className="ant-input-email"
                />
              </Form.Item>
              <Button type="primary" htmlType="submit" loading={loading} className="input-submit-button">
                Send Link
              </Button>
            </Form>
          </div>
        </Col>
      </Row>
      <Col className="contact-container">
        <Card>
          <div className="contact">
            <p>If you encounter any issues, please contact support at:</p>
            <a href="mailto:contact@shankh.tech">contact@shankh.tech</a>
          </div>
        </Card>
      </Col>
    </Content>
  );
};

export default ForgotPassword;
