import { Switch, Route, Redirect } from "react-router-dom";
import "antd/dist/antd.css";
import "./assets/styles/responsive.css";
import "./assets/styles/complianceReport.css";
import "./assets/styles/main.css";
import HomePage from "./pages/HomePage";
import React, { useState } from "react";
import Aboutus from "./pages/Aboutus";
import Products from "./pages/Products";
import Media from "./pages/Media";
import Career from "./pages/Career";
import Contactus from "./pages/Contactus";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import TermsOfService from "./pages/TermsOfService";
import PageLayoutWithModal from "./components/layout/PageLayoutWithModal";
import PasswordReset from "./pages/PasswordReset";
import ForgotPassword from "./pages/ForgotPassword";

function App() {
  return (
    <div className="App">
      <Switch>
        <Route
          path="/homepage"
          exact
          render={() => (
            <PageLayoutWithModal>
              <HomePage />
            </PageLayoutWithModal>
          )}
        />
        <Route
          path="/"
          exact
          render={() => (
            <PageLayoutWithModal>
              <HomePage />
            </PageLayoutWithModal>
          )}
        />
        <Route
          path="/about-us"
          exact
          render={() => (
            <PageLayoutWithModal>
              <Aboutus />
            </PageLayoutWithModal>
          )}
        />
        <Route
          path="/products"
          exact
          render={() => (
            <PageLayoutWithModal>
              <Products />
            </PageLayoutWithModal>
          )}
        />
        <Route
          path="/media"
          exact
          render={() => (
            <PageLayoutWithModal>
              <Media />
            </PageLayoutWithModal>
          )}
        />
        <Route
          path="/career"
          exact
          render={() => (
            <PageLayoutWithModal>
              <Career />
            </PageLayoutWithModal>
          )}
        />
        <Route
          path="/contact-us"
          exact
          render={() => (
            <PageLayoutWithModal>
              <Contactus />
            </PageLayoutWithModal>
          )}
        />
        <Route
          path="/privacy-policy"
          exact
          render={() => (
            <PageLayoutWithModal>
              <PrivacyPolicy />
            </PageLayoutWithModal>
          )}
        />
        <Route
          path="/terms-of-service"
          exact
          render={() => (
            <PageLayoutWithModal>
              <TermsOfService />
            </PageLayoutWithModal>
          )}
        />
        <Route
          path="/forgot-password"
          exact
          render={() => (
            <PageLayoutWithModal>
              <ForgotPassword />
            </PageLayoutWithModal>
          )}
        />

        <Route
          path="/reset-password/:token"
          extact
          render={() => <PasswordReset />}
        />
      </Switch>
    </div>
  );
}

export default App;
