import React, { useState } from "react";
import { Layout, Typography, Modal } from "antd";
import { Content } from "antd/es/layout/layout";
import "antd/dist/antd.css";
import "../assets/styles/privacyPolicy.css";
import "../assets/styles/signinModal.css";

const { Title, Paragraph, Text } = Typography;

const TermsOfService = () => {
  return (
    <>
      <Content style={{ padding: "20px" }}>
        <div className="section">
          <Title level={2} className="title">
            Terms of Service
          </Title>
          <Paragraph className="description">
            <Text strong>Last updated: November 27, 2024</Text>
          </Paragraph>
          <Paragraph className="description">
            These Terms of Service ("Terms") govern your access to and use of
            the Shankh App and associated services. By using the App, you agree
            to comply with these Terms. If you disagree with any part of these
            Terms, you must not use the App.
          </Paragraph>

          <Title level={3} className="subtitle">
            1. Introduction
          </Title>
          <Paragraph className="description">
            Welcome to Shankh App! We are committed to providing a seamless
            experience for tracking and managing your stock investments. By
            accessing or using the App, you agree to be bound by these Terms. If
            you do not agree, please do not use the Service.
          </Paragraph>

          <Title level={3} className="subtitle">
            2. Account Registration
          </Title>
          <Paragraph className="description">
            In order to access certain features of the App, you may need to
            create an account. When registering, you agree to provide accurate,
            complete, and up-to-date information. You are responsible for
            maintaining the confidentiality of your account credentials and all
            activities under your account.
          </Paragraph>

          <Title level={3} className="subtitle">
            3. Use of the Service
          </Title>
          <Paragraph className="description">
            The Shankh App is intended for personal, non-commercial use to help
            you manage and track your stock portfolio. You agree not to use the
            App for any illegal activities or unauthorized purposes.
          </Paragraph>

          <Title level={3} className="subtitle">
            4. Data Collection and Use
          </Title>
          <Paragraph className="description">
            We collect and process certain data, including transaction-related
            emails from your inbox, to update and manage your stock portfolio.
            This data is solely used to provide the Service and is never shared
            with third parties for advertising purposes.
          </Paragraph>

          <Title level={3} className="subtitle">
            5. Privacy and Data Protection
          </Title>
          <Paragraph className="description">
            We respect your privacy and are committed to protecting your
            personal data. For detailed information on how we collect, store,
            and protect your data, please refer to our Privacy Policy, which is
            available [here].
          </Paragraph>

          <Title level={3} className="subtitle">
            6. Restrictions
          </Title>
          <Paragraph className="description">
            You may not engage in any activities that could harm, disable, or
            interfere with the operation of the App or the data contained within
            it. You are also prohibited from attempting to gain unauthorized
            access to the App or its servers.
          </Paragraph>

          <Title level={3} className="subtitle">
            7. Termination
          </Title>
          <Paragraph className="description">
            We may suspend or terminate your access to the App at our
            discretion, without notice, if you violate any of these Terms. Upon
            termination, your right to use the App will cease immediately.
          </Paragraph>

          <Title level={3} className="subtitle">
            8. Limitation of Liability
          </Title>
          <Paragraph className="description">
            To the fullest extent permitted by law, Shankh Technologies, its
            affiliates, and partners are not liable for any direct or indirect
            damages arising from your use of the App. The App is provided "as
            is" without warranty of any kind.
          </Paragraph>

          <Title level={3} className="subtitle">
            9. Changes to the Terms
          </Title>
          <Paragraph className="description">
            We reserve the right to update or modify these Terms at any time.
            When changes are made, the updated date will be reflected at the top
            of this page. Your continued use of the App after changes are made
            constitutes your acceptance of the updated Terms.
          </Paragraph>

          <Title level={3} className="subtitle">
            10. Governing Law
          </Title>
          <Paragraph className="description">
            These Terms will be governed by and construed in accordance with the
            laws of Maharashtra, India, and any disputes will be subject to the
            exclusive jurisdiction of the courts located in Pune, Maharashtra.
          </Paragraph>

          <Title level={3} className="subtitle">
            11. Contact Us
          </Title>
          <Paragraph className="description">
            If you have any questions or concerns about these Terms of Service,
            please contact us at:
          </Paragraph>
          <ul className="description-list">
            <li>
              <Text strong>Email:</Text>{" "}
              <a href="mailto:contact@shankh.tech">contact@shankh.tech</a>
            </li>
            <li>
              <Text strong>Address:</Text> InfinityPool, Pune, IN
            </li>
          </ul>
        </div>
      </Content>
    </>
  );
};

export default TermsOfService;
