import React, { useState, useEffect } from "react";
import { Row, Col, Typography, Card, Button, Input, Form, message } from "antd";
import { Content } from "antd/es/layout/layout";
import "antd/dist/antd.css";
import { useParams } from "react-router-dom";
import { HOST_URL } from "../helper/constant";
import Shankh from "../assets/images/contactus.svg";
import "../assets/styles/passwordReset.css"; // Import the CSS file for the password reset page
import { useHistory } from "react-router-dom";

const { Title } = Typography;

const PasswordReset = () => {
  const { token } = useParams(); // Extract token from the URL
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  const onFinish = async (values) => {
    if (!token) {
      message.error("Token is missing");
      return;
    }

    setLoading(true);
    try {
      const response = await fetch(
        `${HOST_URL}password-reset-link/reset-password/`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            token: token,
            new_password: values.new_password,
          }),
        }
      );

      if (response.ok) {
        message.success("Password reset successfully.")
        setTimeout(() => {
          history.push("/homepage"); 
        }, 2000);
      } else {
        const errorData = await response.json();
        message.error(errorData.message || "Password reset failed.");
      }
    } catch (error) {
      message.error("An error occurred while resetting the password.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Content className="password-reset-container">
      <Row className="container">
        <Col xs={24} sm={12} md={8} className="character">
          <img src={Shankh} alt="Password Reset" />
        </Col>
        <Col xs={24} sm={12} md={16} className="info">
          <div className="message">
            <Title level={2}>Reset Your Password</Title>
            <p>Please enter your new password below.</p>
            <Form onFinish={onFinish} layout="vertical">
              <Form.Item
                name="new_password"
                label="New Password"
                rules={[
                  { required: true, message: "Please enter your new password" },
                ]}
              >
                <Input.Password placeholder="Enter new password" />
              </Form.Item>
              <Form.Item
                name="confirm_password"
                label="Confirm Password"
                rules={[
                  {
                    required: true,
                    message: "Please confirm your new password",
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue("new_password") === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject("Passwords do not match");
                    },
                  }),
                ]}
              >
                <Input.Password placeholder="Confirm new password" />
              </Form.Item>
              <Button type="primary" htmlType="submit" loading={loading}>
                Reset Password
              </Button>
            </Form>
          </div>
        </Col>
      </Row>
      <Col className="contact-container">
        <Card>
          <div className="contact">
            <p>If you encounter any issues, please contact support at:</p>
            <a href="mailto:contact@shankh.tech">contact@shankh.tech</a>
          </div>
        </Card>
      </Col>
    </Content>
  );
};

export default PasswordReset;
