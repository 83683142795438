import React, { useState, useEffect } from "react";
import { Layout, Row, Col, Modal, Typography, Card, Button, Form } from "antd";
import { Content } from "antd/es/layout/layout";
import "antd/dist/antd.css";
// import "..assets/style/aboutus.css";
import HomeHeader from "../components/HomeHeader";
import HomePageFooter from "../components/HomePageFooter";
import SignIn from "./SignIn";
import "../assets/styles/signinModal.css";
import "../assets/styles/homepagefooter.css";
import "../assets/styles/aboutus.css";
import purpose from "../assets/images/purpose.svg";
import portfolio from "../assets/images/shankh-small.svg";
import objective from "../assets/images/objective.svg";
import ceoProfile from "../assets/aboutus/founder.svg";
import teamProfile from "../assets/aboutus/team.svg";
import goal from "../assets/aboutus/primaryGoal.svg";
import e_projection from "../assets/aboutus/economicProjection.svg";
import user_B from "../assets/aboutus/userBenefit.svg";
import freePlatform from "../assets/aboutus/freeplatform.svg";
import financial from "../assets/aboutus/financialLitracy.svg";
import cover from "../assets/aboutus/coverPage.svg";
import "../assets/styles/signinModal.css";
import { useLocation } from "react-router-dom";
import Cookies from "js-cookie";
import { APP_URL, MAIN_DOMAIN, OFFICER_URL } from "../helper/constant";
import { jwtDecode } from "jwt-decode";

import infinityPool from "../assets/aboutus/infinityPool.svg";
const { Title, Text } = Typography;

const Aboutus = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [form] = Form.useForm();

  const handleRedirect = () => {
    // Get the JWT token from cookies
    const token = Cookies.get("shankhjwt");

    if (token) {
      // Decode the token to extract the payload
      const decodedToken = jwtDecode(token);
      const { is_complianceOfficer, is_advisorOfficer } = decodedToken;

      // Redirect based on user role
      if (is_complianceOfficer) {
        window.location.href = `${OFFICER_URL}dashboard`;
      } else if (is_advisorOfficer) {
        window.location.href = `${OFFICER_URL}advisory-dashboard`;
      } else {
        window.location.href = `${APP_URL}TabNavigation/HomeScreen`;
      }
    } else {
      Cookies.remove("shankhjwt", {
        path: "/",
        domain: MAIN_DOMAIN, // Ensure domain is specified
      });
      alert("Please log in again.");
    }
  };

  const showModal = () => {
    if (Cookies.get("shankhjwt")) {
      handleRedirect();
      return;
    } else {
      setIsModalVisible(true);
    }
  };

  const handleCancel = () => {
    form.resetFields();
    setIsModalVisible(false);
  };

  const location = useLocation();
  useEffect(() => {
    if (location.hash) {
      const id = location.hash.replace("#", "");
      const element = document.getElementById(id);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [location]);
  const [isMobileView, setIsMobileView] = useState(window.innerWidth < 800);
  // Update mobile view state on window resize
  const handleResize = () => {
    setIsMobileView(window.innerWidth < 800);
  };
  // Add event listener for window resize
  React.useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <>
      <Content>
        <div className="site-layout-content">
          <div className="company-overview">
            <div className="overlay">
              <Row justify="center" align="middle" className="content-row">
                {isMobileView && (
                  <div className="infinity-img">
                    <img src={infinityPool} alt="InfinityPool" />
                  </div>
                )}
                <Col xs={24} sm={18} md={14} lg={10}>
                  <div className="content-box">
                    <h1>COMPANY OVERVIEW</h1>
                    <Row gutter={[16, 16]} className="company-details">
                      <Col span={12}>
                        <div>
                          <h6>Company Name</h6>
                          <p>InfinityPool</p>
                        </div>
                      </Col>
                      <Col span={12}>
                        <div>
                          <h6>Industry</h6>
                          <p>Fintech</p>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </div>

        <div className="focus-area" id="focus-area">
          <h2 className="focus-title">Focus Area</h2>
          <Row
            gutter={[16, 16]}
            justify="center"
            className="focus-hexagon-conatiner"
          >
            {/* <Col xs={24} sm={12} md={8}> */}
            <div className="hexagon">
              <div className="hexagon-inner">
                <div className="hexagon-content">Wealth Management</div>
              </div>
            </div>
            {/* </Col> */}
            {/* <Col xs={24} sm={12} md={8}> */}
            <div className="hexagon">
              <div className="hexagon-inner">
                <div className="hexagon-content">Financial Education</div>
              </div>
            </div>
            {/* </Col> */}
            {/* <Col xs={24} sm={12} md={8}> */}
            <div className="hexagon">
              <div className="hexagon-inner">
                <div className="hexagon-content">
                  Technology Buildout for Finance
                </div>
              </div>
            </div>
            {/* </Col> */}
          </Row>
        </div>

        <div className="flagship-product">
          <h2>Flagship Product: &nbsp; SHANKH</h2>
          <Row gutter={[16, 16]}>
            <Col xs={24} sm={24} md={8} lg={8}>
              <div className="feature-card">
                <div className="feature-card-img">
                  <img src={purpose} alt="Purpose" />
                </div>
                <div className="feature-card-text">
                  <h4>Purpose</h4>
                  <p>
                    Shankh's purpose is to empower Wealth Growth for a Viksit
                    Bharat by 2047. Our mission is to provide everyone with the
                    tools and knowledge for financial growth.
                  </p>
                </div>
              </div>
            </Col>
            <Col xs={24} sm={24} md={8} lg={8}>
              <div className="feature-card">
                <div className="feature-card-img">
                  <img src={portfolio} alt="Name Origin" />
                </div>
                <div className="feature-card-text">
                  <h4>Name Origin</h4>
                  <p>
                    It is derived from traditional Indian belief associating
                    'Shankh' (Conch) with wealth, prosperity, and longevity. It
                    symbolizes our commitment to enhancing your financial
                    well-being and prosperity.
                  </p>
                </div>
              </div>
            </Col>
            <Col xs={24} sm={24} md={8} lg={8}>
              <div className="feature-card">
                <div className="feature-card-img">
                  <img src={objective} alt="Objective" />
                </div>
                <div className="feature-card-text">
                  <h4>Objective</h4>
                  <p>
                    Shankh is designed to democratize access to sophisticated
                    wealth management strategies. By utilizing a highly advanced
                    AI-based digital ecosystem, our objective is to reduce
                    complexities of finance so investors focus on life goals!
                  </p>
                </div>
              </div>
            </Col>
          </Row>
        </div>

        <div className="founder-info" id="team">
          <Row gutter={[16, 16]}>
            <Col xs={24} sm={24} md={12} lg={8}>
              <div className="ceo-details">
                <img src={ceoProfile} alt="Founder" className="founder-image" />
                <h2>Founder & CEO: Akshay Kunkulol</h2>
                <a
                  href="https://akshaykunkulol.me/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  akshaykunkulol.me
                </a>
              </div>
            </Col>

            <Col xs={24} sm={24} md={12} lg={16}>
              <div className="founder-details">
                <div className="founder-text">
                  <div className="founder-text-title">
                    <text>Expertise & Experience:</text>
                  </div>
                  <div>
                    <text>
                      Extensive background in finance, technology, operations,
                      strategy and product management.
                      <br />
                      Engineering degree in Technology and Honors MBA in Finance
                      & Strategy from Chicago Booth.
                      <br />
                      Global experience in the USA, Japan, and India.
                    </text>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
          <br />
          <Row gutter={[16, 16]}>
            <Col xs={24} sm={24} md={12} lg={8}>
              <div className="ceo-details">
                <img
                  src={teamProfile}
                  alt="Founder"
                  className="founder-image"
                />
                <h2>SHANKH team</h2>
              </div>
            </Col>
            <Col xs={24} sm={24} md={12} lg={16}>
              <div className="founder-details">
                <div className="founder-text">
                  <div className="founder-text-title">
                    <text>Expertise & Experience:</text>
                  </div>
                  <div>
                    <text>
                      Industry Experts from Quantitative Analysis, Artificial
                      Intelligence, Creative Content, Trading, Technology.
                    </text>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>

        <div className="csr-initiatives" id="other-initiatives">
          <h2>Other Initiatives</h2>
          <Row gutter={[16, 8]}>
            <Col xs={24} sm={24} md={12} lg={8}>
              <img src={freePlatform} alt="Name Origin" />
            </Col>
            <Col xs={24} sm={24} md={12} lg={12}>
              <div className="csr-feature">
                <h4>Platform for Retail Investors</h4>
                <text>
                  Provide tools to minimize losses of retail community and
                  prepare smaller investors for appropriate risk management,
                  advanced investments.
                </text>
              </div>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col xs={24} sm={24} md={12} lg={8}>
              <img src={financial} alt="Name Origin" />
            </Col>
            <Col xs={24} sm={24} md={12} lg={12}>
              <div className="csr-feature">
                <h4>Financial Literacy Training</h4>
                <text>
                  Targeted for younger generation to improve financial
                  decision-making.
                </text>
              </div>
            </Col>
          </Row>
        </div>

        <div className="community-section">
          <h2>Join the Shankh Community</h2>
          <p>
            Join us in transforming the wealth management landscape. With
            Shankh, you're not just investor; you're part of a community
            striving for financial success. Get started today and take the first
            step towards maximizing your wealth potential.
          </p>
          <Button type="primary" onClick={showModal}>
            Get Started
          </Button>
        </div>
      </Content>
      <Modal
        title={null}
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={null}
        className="full-screen-modal"
        width="100%"
        style={{ top: 0, padding: 0 }}
        bodyStyle={{ height: "100vh", padding: 0, margin: 0 }}
      >
        <SignIn form={form} onClose={handleCancel} />
      </Modal>
    </>
  );
};

export default Aboutus;
