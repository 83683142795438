import React from "react";
import { Row, Col, Button } from "antd";
import { Content } from "antd/es/layout/layout";
import "antd/dist/antd.css";
import "../assets/styles/signinModal.css";
import "../assets/styles/homepagefooter.css";
import "../assets/styles/career.css";


const Career = () => {
  const reasons = [
    "Intellectual Challenge",
    "Influence and Shape",
    "Informal and Flexible Culture",
    "Innovation Collaboration Transparency",
    "Flat Hierarchy",
    "Sense of Purpose",
  ];

  const jobList = [
    {
      title: "AI Software Developer",
      type: "Full-Time | Remote",
      link: "https://wellfound.com/l/2zewiD",
    },
    {
      title: "Equity and Derivatives Trader",
      type: "Intern | Remote",
      link: "https://angel.co/l/2ynorP",
    },
    {
      title: "Creative Content Writer",
      type: "Full-Time | Remote",
      link: "https://angel.co/l/2ynos1",
    },
    {
      title: "Compliance Officer",
      type: "Full-Time | Remote",
      link: "https://wellfound.com/l/2zKg7h",
    },
    {
      title: "Quant Analyst",
      type: "Full-Time | Remote",
      link: "https://angel.co/l/2ynorJ",
    },
    {
      title: "Financial Advisor RIA",
      type: "Full-Time | Remote",
      link: "https://wellfound.com/company/infinitypool-finnotech",
    },
  ];

  return (
    <>
      <Content style={{ padding: "20px" }}>
        {/* Who We Are Section */}
        <div className="section">
          <h2 className="title">Who We Are?</h2>
          <text className="description">
            The Shankh team at InfinityPool works with cutting-edge technologies
            and financial research. We deliver the best experience to our
            clients.
          </text>
        </div>

        {/* Why Join Us Section */}
        <Row className="join-us-row">
          <Col span={24}>
            <h2 className="join-us-header">Why Join Us?</h2>
          </Col>
          {reasons.map((text, index) => (
            <Col
              key={index}
              xs={24}
              sm={12}
              md={8}
              lg={4}
              className="join-us-col"
            >
              <div className="join-us-box">{text}</div>
            </Col>
          ))}
        </Row>

        {/* We Are Hiring Section */}
        <div className="hiring-section">
          <Row justify="center" align="middle">
            <Col span={24}>
              <h2 className="hiring-title">We are Hiring!</h2>
            </Col>
          </Row>
          {jobList.map((job, index) =>
            index % 2 === 0 ? (
              <Row gutter={[32, 32]} key={index}>
                <Col xs={24} sm={12} md={12} lg={12}>
                  <div className="hiring-job">
                    <div className="hiring-job-content">
                      <div className="job-details">
                        <h3>{job.title}</h3>
                        <p>{job.type}</p>
                      </div>
                      <Button
                        href={job.link}
                        type="primary"
                        target="_blank"
                        className="apply-btn"
                      >
                        Apply
                      </Button>
                    </div>
                  </div>
                </Col>
                {index + 1 < jobList.length && (
                  <Col xs={24} sm={12} md={12} lg={12}>
                    <div className="hiring-job">
                      <div className="hiring-job-content">
                        <div className="job-details">
                          <h3>{jobList[index + 1].title}</h3>
                          <p>{jobList[index + 1].type}</p>
                        </div>
                        <Button
                          href={jobList[index + 1].link}
                          type="primary"
                          target="_blank"
                          className="apply-btn"
                        >
                          Apply
                        </Button>
                      </div>
                    </div>
                  </Col>
                )}
              </Row>
            ) : null
          )}
        </div>
      </Content>
    </>
  );
};

export default Career;
