import React, { useState, useRef } from "react";
import { Layout, Menu, Button, Row, Col, Card, Modal, Form } from "antd";
import "../assets/styles/homepage.css";
import logo from "../assets/images/shankh-logo-hz.svg";
import shankhtransform from "../assets/images/homeShankh.svg";
import bank from "../assets/images/homeUserBank.svg";
import investor from "../assets/images/homeUserInvester.svg";
import compliance from "../assets/images/homeUserCompliance.svg";
import advisor from "../assets/images/homeUserAdvisor.svg";
import integration from "../assets/images/integration.svg";
import portfolio from "../assets/images/portfolioDoctor.svg";
import comprehensive from "../assets/images/homeFeatureComprensive.svg";
import equity from "../assets/images/homeEquityCover.svg";
import mf from "../assets/images/homeMutualFund.svg";
import fixedDeposit from "../assets/images/homeFixedDeposit.svg";
import "../assets/styles/signinModal.css";
import "../assets/styles/homepagefooter.css";
import crypto from "../assets/images/crypto.svg";
import Cookies from "js-cookie";
import { APP_URL, MAIN_DOMAIN, OFFICER_URL } from "../helper/constant";
import { jwtDecode } from "jwt-decode";
import SignIn from "./SignIn.js"

const { Header, Content, Footer } = Layout;

const HomePage = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [form] = Form.useForm();

  const handleRedirect = () => {
    // Get the JWT token from cookies
    const token = Cookies.get("shankhjwt");

    if (token) {
      // Decode the token to extract the payload
      const decodedToken = jwtDecode(token);
      const { is_complianceOfficer, is_advisorOfficer } = decodedToken;

      // Redirect based on user role
      if (is_complianceOfficer) {
        window.location.href = `${OFFICER_URL}dashboard`;
      } else if (is_advisorOfficer) {
        window.location.href = `${OFFICER_URL}advisory-dashboard`;
      } else {
        window.location.href = `${APP_URL}TabNavigation/HomeScreen`;
      }
    } else {
      Cookies.remove("shankhjwt", {
        path: "/",
        domain: MAIN_DOMAIN, // Ensure domain is specified
      });
      alert("Please log in again.");
    }
  };

  const showModal = () => {
    if (Cookies.get("shankhjwt")) {
      handleRedirect();
      return;
    } else {
      setIsModalVisible(true);
    }
  };

  const handleCancel = () => {
    form.resetFields();
    setIsModalVisible(false);
  };

  const scrollContainerRef = useRef(null);

  const scrollLeft = () => {
    scrollContainerRef.current.scrollBy({ left: 200, behavior: "smooth" });
  };

  const scrollRight = () => {
    scrollContainerRef.current.scrollBy({ left: -200, behavior: "smooth" });
  };

  const cardData = [
    { title: "Equity", imgSrc: equity },
    { title: "Mutual Funds", imgSrc: mf },
    { title: "Fixed Deposit", imgSrc: fixedDeposit },
    { title: "Bonds", imgSrc: crypto },
  ];

  return (
    <>
      <Content>
        <Row gutter={[16, 16]} className="intro-section">
          <Col xs={24} sm={24} md={12} lg={12} xl={12} className="intro-text">
            <h1>Maximize Your Wealth Potential</h1>
            <p>
              Are you able to save well but struggle with investing effectively?
              Does your money work for you yet?
            </p>
            <Button type="primary" onClick={showModal}>
              Get Started
            </Button>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={12} className="intro-image">
            <img
              className="transform-logo"
              src={shankhtransform}
              alt="Shankh Transform"
            />
          </Col>
        </Row>

        <div className="introduction-container">
          <h3>Introduction</h3>
          <p>
            Shankh, the flagship product from InfinityPool, leverages advanced
            Artificial Intelligence to simplify wealth management. Leave the
            complexity to us and focus on your life goals. Our platform
            integrates seamlessly with other financial institutions like Banks,
            Broker, etc providing you with holistic & optimized financial
            solutions. As India aims for a $30 trillion economy / $45 trillion
            market cap by 2047, we would like to help everyone get a piece of
            that growing pie.
          </p>
        </div>

        <div className="users-section">
          <h2>Who is it for?</h2>
          <Row gutter={[16, 16]}>
            <Col xs={24} sm={24} md={12} lg={12}>
              <div className="user-card">
                <img src={investor} alt="Investor" className="user-card-img" />

                <div className="user-card-text">
                  <h3>Investors</h3>
                  <p>
                    Shankh empowers investors with advanced AI tools to manage
                    and grow their wealth efficiently. Our platform offers
                    personalized financial wellness solution based on your
                    goals, risk appetite, rigorous data analysis and market
                    trends.
                  </p>
                </div>
              </div>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12}>
              <div className="user-card">
                <img
                  src={compliance}
                  alt="Compliance Officer"
                  className="user-card-img"
                />
                <div className="user-card-text">
                  <h3>Compliance Officers</h3>
                  <p>
                    Our solution aids financial institutes and their vendors
                    become truly compliant in adhering to regulatory standards
                    effortlessly. This win-win solution also improves the
                    financial wellness of employees.
                  </p>
                </div>
              </div>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col xs={24} sm={24} md={12} lg={12}>
              <div className="user-card">
                <img src={advisor} alt="Advisor" className="user-card-img" />
                <div className="user-card-text">
                  <h3>Investment Advisors</h3>
                  <p>
                    We provide a comprehensive digital platform for advisors to
                    offer tailored investment advice to their clients. Improve
                    asset allocation, portfolio optimization with our AI-based
                    SaaS platform.
                  </p>
                </div>
              </div>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12}>
              <div className="user-card">
                <img src={bank} alt="Bank" className="user-card-img" />
                <div className="user-card-text">
                  <h3>
                    Small/Mid-size Banks looking for White Label Wealth Desks
                  </h3>
                  <p>
                    Shankh offers white-label solutions for small/mid-size banks
                    looking to enhance their wealth management services. Our
                    AI-powered platform can be integrated seamlessly, enabling
                    banks to provide cutting-edge financial products to their
                    customers.
                  </p>
                </div>
              </div>
            </Col>
          </Row>
        </div>

        <div className="features-section">
          <h2>Key Features</h2>
          <Row gutter={[16, 16]}>
            <Col xs={24} sm={24} md={8} lg={8}>
              <div className="feature-card">
                <div className="feature-card-img">
                  <img src={integration} alt="Ecosystem Integration" />
                </div>
                <div className="feature-card-text">
                  <h4>Financial Ecosystem Integration</h4>
                  <p>
                    Shankh's integration with the other Banks, Brokers, etc
                    ensures seamless data access and enhanced financial
                    decision-making.
                  </p>
                </div>
              </div>
            </Col>
            <Col xs={24} sm={24} md={8} lg={8}>
              <div className="feature-card">
                <div className="feature-card-img">
                  <img src={portfolio} alt="Portfolio Doctor" />
                </div>
                <div className="feature-card-text" style={{ marginLeft: 10 }}>
                  <h4>Portfolio Doctor</h4>
                  <p>
                    The Portfolio Doctor feature analyzes and optimizes user
                    portfolios, ensuring better risk management and higher
                    returns. This tool is pivotal for advisors and individual
                    investors alike.
                  </p>
                </div>
              </div>
            </Col>
            <Col xs={24} sm={24} md={8} lg={8}>
              <div className="feature-card">
                <div className="feature-card-img">
                  <img
                    src={comprehensive}
                    alt="Comprehensive Investing Options"
                  />
                </div>
                <div className="feature-card-text">
                  <h4>Comprehensive Investing Options</h4>
                  <p>
                    Shankh offers diverse trading options, including equities,
                    mutual funds, fixed deposits, and bonds, allowing users to
                    diversify their investments and manage risks effectively.
                  </p>
                </div>
              </div>
            </Col>
          </Row>
        </div>

        <div className="scroll-container">
          <h2 className="section-title">One Platform, Multiple Choices</h2>
          <div className="scroll-wrapper">
            <button className="scroll-arrow left" onClick={scrollRight}>
              &lt;
            </button>
            <div className="scroll-content" ref={scrollContainerRef}>
              {cardData.map((card, index) => (
                <div
                  className="card"
                  key={index}
                  style={{ backgroundImage: `url(${card.imgSrc})` }}
                >
                  <div className="card-overlay">
                    <div className="card-text">{card.title}</div>
                  </div>
                </div>
              ))}
            </div>
            <button className="scroll-arrow right" onClick={scrollLeft}>
              &gt;
            </button>
          </div>
        </div>

        <div className="community-section">
          <h2>Join the Shankh Community</h2>
          <p>
            Join us in transforming the wealth management landscape. With
            Shankh, you're not just investor; you're part of a community
            striving for financial success. Get started today and take the first
            step towards maximizing your wealth potential.
          </p>
          <Button type="primary" onClick={showModal}>
            Get Started
          </Button>
        </div>
      </Content>
      <Modal
        title={null}
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={null}
        className="full-screen-modal"
        width="100%"
        style={{ top: 0, padding: 0 }}
        bodyStyle={{ height: "100vh", padding: 0, margin: 0 }}
      >
        <SignIn form={form} onClose={handleCancel} />
      </Modal>
    </>
  );
};

export default HomePage;
