import React from "react";
import { Row, Col, Typography, Card, Button } from "antd";
import { Content } from "antd/es/layout/layout";
import "antd/dist/antd.css";
import "../assets/styles/signinModal.css";
import "../assets/styles/homepagefooter.css";
import "../assets/styles/contactus.css";
import contactus from "../assets/images/contactus.svg";

const Contactus = () => {
  return (
    <>
      <Content style={{ padding: "20px" }}>
        <Row className="container">
          <Col xs={24} sm={12} md={8} className="character">
            <img src={contactus} alt="Shankh's Products" />
          </Col>
          <Col xs={24} sm={12} md={16} className="info">
            <div className="message">
              <p>
                If you are interested in Shankh's Products, please fill in this
                form and appropriate team member will get back to you within 1
                business day.
              </p>
              <Button
                href="https://forms.gle/GdebQ7w3LwyPKSSx7"
                type="primary"
                target="_blank"
                className="apply-btn ant-btn-primary fill-form"
              >
                FILL FORM
              </Button>
            </div>
          </Col>
        </Row>
        <Col className="contact-container">
          <Card>
            <div className="contact">
              <p>
                For other queries including participating in our beta programs,
              </p>
              <a href="mailto:contact@shankh.tech">contact@shankh.tech</a>
            </div>
          </Card>
        </Col>
      </Content>
    </>
  );
};

export default Contactus;
