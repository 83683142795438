import React, { useState } from "react";
import { Modal, Form, Layout } from "antd";
import Cookies from "js-cookie";
import { APP_URL, MAIN_DOMAIN, OFFICER_URL } from "../../helper/constant";
import { jwtDecode } from "jwt-decode";
import HomeHeader from "../HomeHeader";
import HomePageFooter from "../HomePageFooter";
import SignIn from "../../pages/SignIn";

const PageLayoutWithModal = ({ children }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [form] = Form.useForm();

  const handleRedirect = () => {
    const token = Cookies.get("shankhjwt");

    if (token) {
      const decodedToken = jwtDecode(token);
      const { is_complianceOfficer, is_advisorOfficer } = decodedToken;

      if (is_complianceOfficer) {
        window.location.href = `${OFFICER_URL}dashboard`;
      } else if (is_advisorOfficer) {
        window.location.href = `${OFFICER_URL}advisory-dashboard`;
      } else {
        window.location.href = `${APP_URL}TabNavigation/HomeScreen`;
      }
    } else {
      Cookies.remove("shankhjwt", { path: "/", domain: MAIN_DOMAIN });
      alert("Please log in again.");
    }
  };

  const showModal = () => {
    if (Cookies.get("shankhjwt")) {
      handleRedirect();
    } else {
      setIsModalVisible(true);
    }
  };

  const handleCancel = () => {
    form.resetFields();
    setIsModalVisible(false);
  };

  return (
    <>
      <Layout className="layout-dashboard">
        <HomeHeader showModal={showModal} />
        <main>{children}</main>
      </Layout>
      <HomePageFooter />
      <Modal
        title={null}
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={null}
        className="full-screen-modal"
        width="100%"
        style={{ top: 0, padding: 0 }}
        bodyStyle={{ height: "100vh", padding: 0, margin: 0 }}
      >
        <SignIn form={form} onClose={handleCancel} />
      </Modal>
    </>
  );
};

export default PageLayoutWithModal;
