import React, { useState } from "react";
import { Row, Col } from "antd";
import { Content } from "antd/es/layout/layout";
import "antd/dist/antd.css";
import "../assets/styles/signinModal.css";
import "../assets/styles/homepagefooter.css";
import "../assets/styles/media.css";
import mps from "../assets/images/most_promising_startup.svg";
import wiii from "../assets/images/why_invest_in_India.svg";


const Media = () => {
  
  const blogs = [
    {
      id: 1,
      title: "20 Most Promising Startups To Watch – 2023",
      date: "March 30, 2023",
      author: "Akshay Kunkulol",
      content:
        "“As India is poised to become 30+ trillion dollars economy, we would like everyone to have piece of that pie.” – Akshay Kunkulol, founder & CEO of InfinityPool Finnotech quoted watch.",
      image: mps,
      href: "https://businessconnectindia.in/infinitypool-finnotech-private-limited/",
    },
    {
      id: 2,
      title: "Why Invest in India?",
      date: "January 09, 2023",
      author: "Akshay Kunkulol",
      content:
        "Change is the only constant and with the recent pandemic having thrown our lives out of gear, the impact on different industries in India has been varied, but what.",
      image: wiii,
      href: "http://akshaykunkulol.me/blogs/why-invest-in-india/",
    },
  ];

  return (
    <>
      <Content>
        <div className="blogs-container">
          {blogs.map((blog, index) => (
            <Row gutter={[16, 18]} className="blog-row" key={blog.id}>
              <Col
                xs={24}
                md={8}
                className={`image-col ${
                  index % 2 === 0 ? "order-1-md" : "order-2-md"
                }`}
              >
                <img src={blog.image} alt={blog.title} className="blog-image" />
                <div className="blog-meta">
                  <span className="blog-author">{blog.author}</span>
                  <span className="blog-date">{blog.date}</span>
                </div>
              </Col>
              <Col
                xs={24}
                md={16}
                className={`text-col ${
                  index % 2 === 0 ? "order-2-md" : "order-1-md"
                }`}
              >
                <div className="blog-text">
                  <h4>{blog.title}</h4>
                  <p>{blog.content}</p>
                  <a href={blog.href} target="_blank" className="read-more">
                    Read More
                  </a>
                </div>
              </Col>
            </Row>
          ))}
        </div>
      </Content>
    </>
  );
};

export default Media;
